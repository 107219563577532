import { CommonModule } from '@angular/common';
import { Component, OnDestroy } from '@angular/core';

import { NavigationService } from '@frontend/vanilla/core';
import { PCComponent } from '@frontend/vanilla/features/content';
import { LoggerFactory, PokerLogger } from '@pokercore/module/logging';

import { TrustAsResourceUrlPipe } from '../../pipes/trust-as-resource-url.pipe';
import { PokerWebClientSharedService } from '../../poker-web-client-shared.service';

@Component({
    selector: 'pwc-portal-pages-container',
    templateUrl: 'portal-pages-container.component.html',
    standalone: true,
    imports: [CommonModule, TrustAsResourceUrlPipe],
})
export class PortalPagesContainerComponent extends PCComponent implements OnDestroy {
    public pageUrl: string;
    private readonly logger: PokerLogger;

    constructor(
        private sharedService: PokerWebClientSharedService,
        loggerFactory: LoggerFactory,
        private navigation: NavigationService,
    ) {
        super();
        this.logger = loggerFactory.getLogger('portalpagescontainercomponentLoggingComponent');
        this.sharedService.getPortalPage().subscribe((pageInfo: any) => {
            pageInfo && pageInfo !== null ? this.setPageUrl(pageInfo) : this.navigation.goTo('/play');
        });
    }

    setPageUrl(pageInfo: any) {
        this.logger.info('pageUrl..!!!!' + JSON.stringify(pageInfo));
        // this.clientToServer.updatePageTitle(pageInfo.text);
        this.sharedService.setPageHeaderTitle({
            title: pageInfo.text,
            url: '/play',
            showBalanceBlock: pageInfo.showBalanceBlock ? pageInfo.showBalanceBlock : false,
        });
        this.pageUrl = window.location.href.includes('dev') ? pageInfo.url.replace(/(qa1|qa2|test)/g, 'dev') : pageInfo.url;
    }

    // Function to get the value of a specific cookie by name
    getCookie(name: string): string | null {
        let cookieArr = document.cookie.split(';');
        for (let i = 0; i < cookieArr.length; i++) {
            let cookiePair = cookieArr[i].split('=');
            if (name === cookiePair[0].trim()) {
                return decodeURIComponent(cookiePair[1]);
            }
        }
        return null;
    }

    deleteCookieIfMatches(cookieName: string, expectedValue: string) {
        // Get the current value of the cookie
        let currentValue = this.getCookie(cookieName);
        // Check if the current value matches the expected value
        if (currentValue === expectedValue) {
            // Function to delete a cookie by setting its expiry date to the past
            document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=.partypokerspins.com`;
            // console.log(`Cookie(NativeApp) with value(POKER) deleted.`);
        }
        // else {
        //     console.log(`Cookie(NativeApp) does not match the expected value(POKER).`);
        // }
    }

    ngOnDestroy(): void {
        this.deleteCookieIfMatches('NativeApp', 'POKER');
    }
}
